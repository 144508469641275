import * as React from "react";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import { Link } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { Trans } from "react-i18next";
import gif404 from "../images/404.gif"


// markup
const NotFoundPage = () => {
  return (
    <>
      <SEO title="404"></SEO>
      <Layout>
        <div className="text-center flex items-center flex-col">
          <h1>
            <Trans>This page does not exist</Trans>
          </h1>
          <p className="mb-5">
            <Trans>Make sure you entered the correct URL</Trans>
          </p>
          <img src={gif404} alt="Page does not exists" className="w-96 mb-20" />
          <Link to="/">
            <Trans>Go back home</Trans> &#10230;
          </Link>
        </div>
      </Layout>
    </>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
